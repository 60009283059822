@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.bigSpace {
    margin-bottom: 130px;
}

.spaceDown {
    margin-bottom: 50px;
}

.transparent {
    background-color: inherit
}

body{
    background-image: url('../images//boonji-star-bg.gif');
}

.warningAnnouncement {
    margin: 15px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: palevioletred;
    padding-bottom: 10px;
}
