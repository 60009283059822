@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
.title{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 51px;
    /* identical to box height, or 127% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #0C37A0;
    align-items: center;
    justify-content: center;
}

.exampleTitle {    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 51px;
    /* identical to box height, or 127% */
    display: flex;
    align-items: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    color: #FFFFFF;
}

.bear{
border: 3px solid #784FB1;
box-sizing: border-box;
border-radius: 12px;

}

.announcementBox {
    background: #674BA2;
    border-radius: 16px;
    text-align: 'center';
    line-height: 60pxs;
    padding: 15px;    
    width: 300px;
}

.title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #71BBF2;
    padding-bottom: 10px;
}

.subTitle {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    text-align: center;

    color: #FFFFFF;
}
.typeBox{
    background: linear-gradient(180deg, #B2477F 0%, #C2608A 100%);
    border-radius: 12px 0px 0px 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.typeText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.descriptionBox {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px 12px 12px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.descriptionText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    /* identical to box height, or 17px */

    color: #FFFFFF;
}
.section {
    background: linear-gradient(180deg, #291743 5.07%, rgba(89, 59, 131, 0.8) 100%);
    padding: 30px;
    border-radius: 16px;
}

.looleeTypeBox {
    background: linear-gradient(180deg, #B2477F 0%, #C2608A 100%);
    border-radius: 12px 0px 0px 12px;
}

.looleeTypeBox.title {    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    
}

.mintBox {
    padding-top: 5px;
    padding-bottom: 5px;
}

.connectWallet {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: inherit; */
    background: linear-gradient(180deg, #291743 5.07%, rgba(89, 59, 131, 0.8) 100%);
}

.countdown {
    background: linear-gradient(180deg, #291743 5.07%, rgba(89, 59, 131, 0.8) 100%);
    padding: 15px;
}

a:link {
    color: hotpink;
  }
  
  /* visited link */
  a:visited {
    color: #71BBF2;
  }
  
  /* mouse over link */
  a:hover {
    color: hotpink;
  }
.barBackground {
    background-image: url(/static/media/boonji-star-bg.661ae23e.gif);
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
img {
    width: 192px;
    height: 195px;
}
img.social{
    width: 20px;
    height: 15px;

}

.text{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 51px;
    /* or 425% */
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.bigSpace {
    margin-bottom: 130px;
}

.spaceDown {
    margin-bottom: 50px;
}

.transparent {
    background-color: inherit
}

body{
    background-image: url(/static/media/boonji-star-bg.661ae23e.gif);
}

.warningAnnouncement {
    margin: 15px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: palevioletred;
    padding-bottom: 10px;
}

