@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.announcementBox {
    background: #674BA2;
    border-radius: 16px;
    text-align: 'center';
    line-height: 60pxs;
    padding: 15px;    
    width: 300px;
}

.title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #71BBF2;
    padding-bottom: 10px;
}

.subTitle {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    text-align: center;

    color: #FFFFFF;
}