@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.title{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 51px;
    /* identical to box height, or 127% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #0C37A0;
    align-items: center;
    justify-content: center;
}

.exampleTitle {    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 51px;
    /* identical to box height, or 127% */
    display: flex;
    align-items: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    color: #FFFFFF;
}

.bear{
border: 3px solid #784FB1;
box-sizing: border-box;
border-radius: 12px;

}
