@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
img {
    width: 192px;
    height: 195px;
}
img.social{
    width: 20px;
    height: 15px;

}

.text{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 51px;
    /* or 425% */
    display: flex;
    align-items: center;

    color: #FFFFFF;
}
