@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.typeBox{
    background: linear-gradient(180deg, #B2477F 0%, #C2608A 100%);
    border-radius: 12px 0px 0px 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.typeText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.descriptionBox {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px 12px 12px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.descriptionText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    /* identical to box height, or 17px */

    color: #FFFFFF;
}