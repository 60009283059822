@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.section {
    background: linear-gradient(180deg, #291743 5.07%, rgba(89, 59, 131, 0.8) 100%);
    padding: 30px;
    border-radius: 16px;
}

.looleeTypeBox {
    background: linear-gradient(180deg, #B2477F 0%, #C2608A 100%);
    border-radius: 12px 0px 0px 12px;
}

.looleeTypeBox.title {    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    
}

.mintBox {
    padding-top: 5px;
    padding-bottom: 5px;
}

.connectWallet {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: inherit; */
    background: linear-gradient(180deg, #291743 5.07%, rgba(89, 59, 131, 0.8) 100%);
}

.countdown {
    background: linear-gradient(180deg, #291743 5.07%, rgba(89, 59, 131, 0.8) 100%);
    padding: 15px;
}

a:link {
    color: hotpink;
  }
  
  /* visited link */
  a:visited {
    color: #71BBF2;
  }
  
  /* mouse over link */
  a:hover {
    color: hotpink;
  }